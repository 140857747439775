import { graphql, useStaticQuery } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

/**
 * "Sails" staves med stort S.
 * Vores logo vises altid sammen med "Sails" hvis ikke "Sails" fremgår tydeligt af konteksten.
 *
 */

const colors = {
  brand: '#06B6D4',
  light: '#F1F5F9',
  dark: '#1E293B',
};

const BrandAssets = () => {
  const { brandAssets } = useStaticQuery(graphql`
    query {
      brandAssets: allFile(
        filter: { relativePath: { eq: "brand-assets.zip" } }
      ) {
        edges {
          node {
            relativePath
            extension
            publicURL
            base
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title="Brand aktiver" />
      <div className="bg-light-blue-50 w-full py-28 pt-36">
        <div className="container">
          <h1 className="heading-sm text-center">Brand aktiver</h1>
          <p className="sub-sm mx-auto max-w-2xl text-center mt-8">
            Tak for din interesse i Sails. Vi har et par guidelines for hvordan
            vores brand skal benyttes. Brug et kort øjeblik på at gøre dig klog
            på dem.
          </p>
        </div>
      </div>
      <div className="container max-w-4xl py-24">
        <div className="prose lg:prose-lg">
          <h2>Vores navn</h2>
          <p>
            "Sails" staves altid med et stort s. Undgå at skrive "Sales" eller
            "get-sails".
          </p>

          <h2>Vores logo</h2>
          <p>
            Vi bruger altid vores ikon sammen med "Sails". På en lys baggrund
            bruges mørk tekst, og på en mørk baggrund bruges lys tekst.
          </p>
          <StaticImage
            src="../images/logo-guideline.png"
            alt="Logo guideline"
            placeholder="blurred"
            objectFit="contain"
          />

          <h2>Vores farver</h2>
          <p>
            Vores brand-farve er en cyanblå med hex-koden{' '}
            <span className="font-mono font-semibold">{colors.brand}</span>.
          </p>

          <div className="flex">
            <div
              className="w-32 h-32 mr-4 text-cyan-50 rounded-full text-sm font-bold font-mono flex items-center justify-center"
              style={{ backgroundColor: colors.brand }}
            >
              {colors.brand}
            </div>
            <div
              className="w-32 h-32 mr-4 rounded-full text-sm font-bold font-mono flex items-center justify-center"
              style={{ backgroundColor: colors.light }}
            >
              {colors.light}
            </div>
            <div
              className="w-32 h-32 text-gray-100 rounded-full text-sm font-bold font-mono flex items-center justify-center"
              style={{ backgroundColor: colors.dark }}
            >
              {colors.dark}
            </div>
          </div>

          <h2>Inden du bruger vores logo</h2>
          <p>
            Her er en venlig påmindelse om, at vi har ophavsretten til vores
            logo og øvrigt indhold på siden. Hvis du gerne vil bruge vores logo
            ifm. en artikel, en nyhed eller lignende, kan du her læse hvad der
            er vigtigt du forholder dig til.
          </p>

          <h4>Det må du godt</h4>
          <ul>
            <li>
              Giv rigeligt med plads omkring vores logo når du bruger det. Du
              kan anvende vores logo og gøre det stort eller du kan gøre det
              lille, så længe der er rigeligt med plads omkring det og det ikke
              føles rodet.
            </li>
            <li>
              Skriv til os inden du bruger vores logo eller grafik i din
              artikel, nyhed eller lignende. Vi vil så gerne høre om hvor vi
              bliver nævnt.
            </li>
          </ul>

          <h4>Det må du ikke</h4>
          <ul>
            <li>Ændre filerne på nogen måde.</li>
            <li>
              Vise vores grafik og logoer på en måde der insinuerer en relation,
              affiliering, tilknytning eller godkendelse fra Sails af dit
              produkt, din tjeneste eller virksomhed, uden du har fået skriftlig
              tilladelse fra os.
            </li>
            <li>
              Bruge vores grafik og logoer som en del af dit eget produkt, din
              virksomheds eller tjenestes navn.
            </li>
            <li>
              Kombinere vores grafik og logoer med enhver anden grafik uden
              skriftligt samtykke fra os.
            </li>
          </ul>

          <h2>Download</h2>
          <p>
            Download vores logoer som{' '}
            <a href={brandAssets.edges[0].node.publicURL}>EPS og PNG</a> filer.
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default BrandAssets;
